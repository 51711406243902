'use client'

import Reveal from '@/components/reveal'
import { siteConfig } from '@/config/site.config'
import { isBrowser } from '@/lib/utils/utils'
import { useEffect, useRef, useState } from 'react'
import { default as ReactPlayer } from 'react-player/youtube'
import { I18nDictionary, I18nValue, getLangCode, useI18n, useI18nValue } from '../../i18n/lang-context'
import { Flex, FlexCol } from '../../ui/flex'
import { Section } from '../section'
import { SectionTitle } from '../section-title'

const I18N: I18nDictionary = {
  title: { en: 'How does it work', fr: 'Comment ça marche' }
}

const lines: I18nValue[] = [
  {
    en: 'We create your PSAN account and provide you with the unique address dedicated to your crypto-assets in accordance with the escrow agreement previously signed.',
    fr: 'Nous créons votre compte PSAN et vous communiquons l’adresse unique dédiée à vos crypto-actifs conformément à la convention de séquestre préalablement signée.'
  },
  {
    en: 'You transfer your funds, which are then secured, locked, and their origin verified.',
    fr: 'Vous transférez vos fonds, ceux-ci sont sécurisés, bloqués et leur origine contrôlée.'
  },
  {
    en: 'We confirm to the notaries, banks, and lawyers involved in the operation the proper deposit of the funds and will issue them an escrow certificate.',
    fr: 'Nous confirmons aux notaires, banques et avocats impliqués dans l’opération la bonne mise en dépôt des fonds et leur délivrerons une attestation de séquestre.'
  },
  {
    en: 'If the operation is completed, upon receipt of the funding request, the crypto-assets are converted into Euros and then transferred to the recipient.',
    fr: 'Si l’opération se réalise, à la réception de l’appel de fonds, les crypto-actifs sont convertis en Euros, puis transférés au destinataire.'
  },
  {
    en: 'If the operation is cancelled, your crypto-assets are returned to you. Without conversion to Euros.',
    fr: 'Si l’opération est annulée, vos crypto-actifs vous sont retournés. Sans conversion €.'
  }
]

function VideoPlayer({ playing }: { playing: boolean }) {

  const [hasWindow, setHasWindow] = useState(false)
  useEffect(() => setHasWindow(isBrowser()), [])

  const getVideoUrl = () => {
    switch (getLangCode()) {
      case 'en':
        return siteConfig.videoUrlEmbeddedEn
      case 'fr':
        return siteConfig.videoUrlEmbeddedFr
      default:
        return null
    }
  }

  return (!hasWindow || !getVideoUrl()) ? (<></>) : (
    <div id='VIDEO_WRAPPER' className='relative h-full w-full p-0'>
      <div id='VIDEO_CONTAINER' className='relative h-full w-full overflow-hidden rounded-xl shadow-md'>
        <ReactPlayer
          url={getVideoUrl() as string}
          playing={playing}
          controls={true}
          muted={true}
          volume={0.1}
          width='100%'
          height='100%'
        />
      </div>
    </div>
  )
}

export function DDHowDoesItWork() {

  const i18n = useI18n(I18N)
  const i18nValue = useI18nValue()

  const [videoPlaying, setVideoPlaying] = useState<boolean>(false)
  const [videoVisible, setVideoVisible] = useState<boolean>(false)
  const videoContainerRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setVideoPlaying(entry.isIntersecting)
        setVideoVisible(entry.isIntersecting)
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.5 // 50% visible
      }
    )
    const handleFocus = () => {
      if (videoVisible) setVideoPlaying(true)
    }
    const handleBlur = () => {
      window.setTimeout(function () {
        if (document.activeElement !== document.querySelector('iframe')) {
          setVideoPlaying(false)
        }
      }, 0)
    }
    if (videoContainerRef.current) observer.observe(videoContainerRef.current)
    window.addEventListener('focus', handleFocus)
    window.addEventListener('blur', handleBlur)
    return () => {
      if (videoContainerRef.current) observer.unobserve(videoContainerRef.current)
      window.removeEventListener('focus', handleFocus)
      window.removeEventListener('blur', handleBlur)
    }
  }, [videoVisible])

  return (
    <Section id='howItWorks' containerClassName='bg-background2'>
      <SectionTitle title={i18n.title} />
      <Flex className='w-full flex-col flex-nowrap items-center justify-center gap-8 md:gap-16 lg:flex-row'>
        <Flex className='relative w-full justify-center lg:w-[calc(50%-16px)] lg:justify-end'>
          <div className='relative aspect-square w-[80vw] max-w-md sm:w-full'>
            <div className='absolute left-[-20px] top-[20px] z-0 h-full w-full rounded-xl bg-primaryBright sm:left-[-30px] sm:top-[30px] md:left-[-40px] md:top-[40px]'></div>
            <div className='relative z-10 h-full w-full rounded-xl bg-primaryBright shadow-lg' ref={videoContainerRef}>
              <VideoPlayer playing={videoPlaying} />
            </div>
          </div>
        </Flex>
        <FlexCol id='MESSAGE' className='w-full gap-4 p-2 sm:gap-6 sm:p-6 md:p-8 lg:w-[calc(50%-16px)] lg:p-0'>
          {lines.map((line: I18nValue, i: number) => (
            <Reveal duration={250} delay={100 + i * 100} translateY={50} className='flex flex-nowrap gap-4' key={i}>
              <div className='font-bold text-primary dark:text-primaryBright'>{i + 1}.</div>
              <div className=''>{i18nValue(line)}</div>
            </Reveal>
          ))}
        </FlexCol>
      </Flex>
    </Section>
  )
}
