'use client'

import { cn } from '@/lib/utils/utils'
import { useMounted } from '@/lib/utils/utils-client'
import { useState } from 'react'
import { MdKeyboardArrowDown } from 'react-icons/md'
import { I18nDictionary, I18nValue, useI18n, useI18nValue } from '../../i18n/lang-context'
import { Flex, FlexCol } from '../../ui/flex'
import { Section } from '../section'
import { SectionTitle } from '../section-title'

const I18N: I18nDictionary = {
  title: { en: 'FAQ', fr: 'FAQ' }
}

const QUESTIONS: Question[] = [
  {
    question: {
      en: 'What needs does Digital Deposit address?',
      fr: 'A quels besoins répond Digital Deposit ?'
    },
    answer: {
      en: 'Our solution addresses the issue of reusing crypto assets in the real economy: our goal is to allow crypto savers to invest in various sectors of the real economy, with maximum security and legitimacy towards all participants and stakeholders.',
      fr: 'Notre solution permet de répondre à la problématique du réemploi des crypto actifs dans l’économie réelle : notre but est de permettre aux épargnants cryptos d’investir dans différents secteurs de l’économie réelle, avec un maximum de sécurité et de légitimité envers l’ensemble des intervenants et parties prenantes.'
    }
  },
  {
    question: {
      en: 'Who is Digital Deposit intended for?',
      fr: 'A qui s’adresse la solution Digital Deposit ?'
    },
    answer: {
      en: `When you want to reinvest your digital assets in the real economy (stocks, company shares, real estate investment, donation, loan, etc.), it\'s necessary to use various intermediaries (banks, lawyers, notaries).\n
      However, due to the recent emergence of this asset class, the flows generated are not always easy to grasp for these participants.\n
      That\'s why we\'ve created a comprehensive solution to enable a smooth and transparent process, clear to each of the stakeholders involved in the transactions.`,
      fr: `Lorsque l’on souhaite réinvestir ses actifs numériques dans l’économie réelle (actions, parts de sociétés, investissement immobilier, donation, prêt…), le recours à différents intermédiaires (banques, avocats, notaires) est nécessaire.\n
      Cependant, du fait de l’émergence récente de cette classe d’actif, les flux générés ne sont pas toujours faciles à appréhender pour ces intervenants.\n
      C’est pourquoi nous avons créé une solution globale afin de permettre un processus fluide et transparent, lisible pour chacune des parties prenantes impliquées dans les transactions.`
    }
  },
  {
    question: {
      en: 'How are the funds secured?',
      fr: 'Comment sont sécurisés les fonds ?'
    },
    answer: {
      en: 'We prioritize the security of your assets and transactions. We work exclusively with recognized and PSAN-certified providers, and the funds are secured through professional and proven solutions.',
      fr: 'Nous priorisons la sécurité de vos actifs et transactions. Nous travaillons uniquement avec des prestataires reconnus et certifiés PSAN, les fonds sont sécurisés par le biais de solutions professionnelles et éprouvées.'
    }
  },
  {
    question: {
      en: 'Do I have visibility on my funds during the escrow phase?',
      fr: 'Est-ce que j\'ai de la visibilité sur mes fonds lors de la phase de séquestre ?'
    },
    answer: {
      en: 'Transparency is a key value of our service; thus, throughout the escrow period, you have consultative access to your assets. Your assets will not be mixed with those of other users and will be segregated on a unique address.',
      fr: 'La transparence est une valeur importante de notre service, aussi, durant toute la durée du séquestre, vous avez un accès en consultation sur vos actifs. Vos actifs ne seront pas mixés avec ceux des autres utilisateurs, et ségrégués sur une adresse unique.'
    }
  },
  {
    question: {
      en: 'Does Digital Deposit allow avoiding all taxes when purchasing a property?',
      fr: 'Digital Deposit permet-elle d’éviter toute fiscalité lors de l’achat d’un bien ?'
    },
    answer: {
      en: `No, Digital Deposit does not possess a magic wand that allows escaping the taxation applicable to the contemplated transactions.\n
      However, since the taxable event is the conversion of your cryptos into fiat, our solution allows this operation to be carried out only at the conclusion of the transaction. Thus, in case of postponement or cancellation of the operation, you won't risk having converted your assets in vain and thus being unnecessarily liable for the flat tax.\n
      Moreover, our network of partners will be able to support you in this area.`,
      fr: `Non, Digital Deposit ne dispose pas d’une baguette magique permettant d’échapper à la fiscalité en vigueur pour les opérations envisagées.\n
      Cependant, le fait générateur étant la conversion de vos cryptos en fiat, notre solution permet de ne réaliser cette opération qu’à la conclusion de l’opération. Ainsi, en cas de report ou d’annulation de l’opération, vous ne risquez pas d’avoir converti vos actifs en vain et ainsi d’être inutilement redevable de la flat tax.\n
      De plus, notre réseau de partenaires sera à même de vous accompagner dans ce domaine`
    }
  },
  {
    question: {
      en: 'Why avoid routing my funds through my bank, I have nothing to hide!',
      fr: 'Pourquoi éviter de faire transiter mes fonds par ma banque, je n’ai rien à cacher !'
    },
    answer: {
      en: `Even when their clients act in good faith and provide the appropriate documentation, some traditional banking institutions still sometimes view digital assets unfavorably.\n
      Therefore, cases of unilateral bank account closures, blockages, or refusals to receive funds from the sale of crypto-assets are unfortunately common.\n
      Our solution enables the direct transfer of the sale proceeds to the appropriate beneficiary, without the need to go through your bank.`,
      fr: `Même lorsque leurs clients sont de bonne foi, et leur fournissent les justificatifs appropriés, certains établissements bancaires traditionnels voient encore parfois les actifs numériques d’un mauvais œil.\n
      Ainsi les cas de fermetures unilatérales de comptes bancaires, de blocages ou de refus de réceptionner les fonds provenant d’une vente de crypto-actifs sont malheureusement fréquents.\n
      Notre solution permet le transfert direct du produit de la vente vers le bénéficiaire adéquat, sans qu’il soit indispensable de passer par votre banque.`
    }
  },
  {
    question: {
      en: 'What other services do you offer?',
      fr: 'Quels autres services offrez-vous ?'
    },
    answer: {
      en: `Our service can include tax advice that will allow you to understand the rules related to the capital gains generated by the transaction.\n
      This way, you can best anticipate the tax provisioning that will be generated by the operation.\n
      We can also connect you with our network of partners specialized in this type of advice.`,
      fr: `Notre prestation pourra intégrer un conseil fiscal qui vous permettra de connaître les règles liées aux plus values générées par l’opération.\n
      Vous pourrez ainsi anticiper de la meilleure manière qui soit le provisionnement de l’impôt qui sera généré par l'opération.\n
      Nous pouvons également vous mettre en relation avec notre réseau de partenaires spécialisés dans ce type de conseils.`
    }
  },
  {
    question: {
      en: 'What types of operations does Digital Deposit allow to address?',
      fr: 'A quel type d’opérations Digital Deposit permet-elle de répondre ?'
    },
    answer: {
      en: `In general, Digital Deposit allows for any operation requiring the transfer of digital assets to a trusted third party (lawyer, notary), such as, for example:\n
      Real Estate Purchase\n
      Acquisition of business assets\n
      Contribution of digital assets to a company`,
      fr: `Et de manière générale, Digital Deposit permet de réaliser toute opération nécessitant le transfert d’actifs numériques à un tiers de confiance (avocat, notaire), telle que par exemple\n
      Achat Immobilier\n
      Achat de fonds de commerce\n
      Apport d’actifs numériques dans une société`
    }
  },
  {
    question: {
      en: 'Who is the PSAN partner used by the solution?',
      fr: 'Quel est le partenaire PSAN utilisé par la solution ?'
    },
    answer: {
      en: 'The PSAN partner used by our solution is Nilos, registered with the AMF under number E2023-074. Nilos provides a secure and compliant framework for crypto transactions and custody, aligning with the latest regulatory standards. For more information, visit (https://www.nilos.io).',
      fr: 'Le partenaire PSAN utilisé par notre solution est Nilos, enregistré auprès de l\'AMF sous le numéro E2023-074. Nilos fournit un cadre sécurisé et conforme pour les transactions et la sécurisation de cryptos, conformément aux dernières normes réglementaires. Pour plus d\'informations, visitez (https://www.nilos.io).'
    }
  }
]

export type Question = {
  question: I18nValue
  answer: I18nValue
}

export function DDQuestion({ question, index, opened, setOpened }: { question: Question, index: number, opened: boolean, setOpened: (i: number) => void }) {

  const i18nValue = useI18nValue()

  return (
    <FlexCol className='w-full transition-all' key={index}>
      <Flex id='QUESTION_LINE' className={cn('w-full cursor-pointer flex-nowrap items-center justify-between gap-2 rounded-md p-2 px-3 text-lg hover:bg-background2 hover:opacity-100 sm:gap-4 sm:p-3 sm:px-4', opened ? 'bg-background2' : 'opacity-80')} onClick={() => setOpened(opened ? -1 : index)}>
        <div id='QUESTION' className='select-none text-[15px] font-medium sm:text-lg'>{i18nValue(question.question)}</div>
        <div id='ARROW' className='text-lg font-semibold opacity-70'>
          <MdKeyboardArrowDown size={24} className={cn('transition-all', opened ? 'rotate-180' : '')} />
        </div>
      </Flex>
      <div id='ANSWER' className={cn('mt-2 w-full rounded-lg bg-background2 p-2 px-3 text-[14px] transition-all duration-300 sm:mt-3 sm:p-3 sm:px-4 sm:text-base', opened ? '' : 'z-0 my-0 h-0 py-0 opacity-0 sm:my-0 sm:py-0')}>
        <Paragraphs input={i18nValue(question.answer)} className={cn(opened ? '' : 'hidden')} />
      </div>
    </FlexCol>
  )
}

export function DDFAQ() {

  const i18n = useI18n(I18N)
  const [opened, setOpened] = useState<number>(-1)

  const mounted = useMounted()
  if (!mounted) return null

  return (
    <Section>

      <SectionTitle title={i18n.title} desc={i18n.desc} />

      <FlexCol id='QUESTIONS' className='w-full gap-2 sm:gap-3'>
        {QUESTIONS.map((question, i) => <DDQuestion question={question} opened={opened === i} setOpened={setOpened} index={i} key={i} />)}
      </FlexCol>

    </Section>
  )
}

function Paragraphs({ input, className = '' }: { input: String, className?: string }) {
  return <div>
    {input.split('\n').map((p, i) => (
      <div key={i} className={className}>
        {p}<br />
      </div>
    )
    )}
  </div>
}
