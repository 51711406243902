'use client'

import { I18nValue, useI18nValue } from '@/components/i18n/lang-context'
import Reveal from '@/components/reveal'
import { cn } from '@/lib/utils/utils'
import Image from 'next/image'
import { BiSolidQuoteAltLeft, BiSolidQuoteAltRight } from 'react-icons/bi'
import { Flex } from '../../ui/flex'
import { useScreen } from '../../utils/windowSize'
import { Section } from '../section'


const TESTIMONIES: Testimony[] = [
  {
    message: {
      en: 'Our profession is witnessing the emergence of a new category of clients: blockchain users and digital asset holders. This brings numerous challenges and questions. Digital Deposit has everything it takes to become the trusted intermediary between these two still separate worlds.',
      fr: 'Notre profession voit l\'émergence d’une nouvelle catégorie de clients, utilisateurs de blockchain et détenteurs d’actifs numériques. Cela génère de nombreux challenges et questionnements. Digital Deposit a tout pour devenir l’intermédiaire de confiance entre ces 2 mondes encore hermétiques.'
    },
    who: {
      en: 'Thomas M., Notary',
      fr: 'Thomas M., Notaire'
    },
    initials: 'TM'
    // image: '/testimony-1.png'
  },
  {
    message: {
      en: 'I often hear about digital assets, and I know some of my clients want to integrate them into their company treasury. It\'s good to know that a solution like Digital Deposit exists. I know I can turn to them when a transaction involving digital assets comes my way.',
      fr: 'J’entends souvent parler d’actifs numériques, je sais que certains de mes clients souhaitent les intégrer dans leur trésorerie d’entreprise, C’est bien de savoir qu’une solution comme Digital Deposit existe, je sais que je peux m’adresser à eux lorsqu’une opération impliquant des actifs numériques se présente à moi.'
    },
    who: {
      en: 'Mathis C., Lawyer',
      fr: 'Mathis C., Avocat'
    },
    initials: 'MC'
    // image: '/testimony-2.png'
  },
  {
    message: {
      en: 'At Barnes, we already conduct sales in cryptocurrencies in the American market. Having a solution like Digital Deposit facilitates the adoption of this payment method in Europe, and more specifically in France, unlocking additional capital.',
      fr: 'Chez Barnes nous réalisons déjà des ventes en crypto-monnaies sur le marché américain. Avoir une solution comme Digital Deposit permet d’accompagner l’adoption de ce moyen de paiement en Europe, et plus spécifiquement en France, y débloquant des capitaux supplémentaires.'
    },
    who: {
      en: 'Hubert G., Real Estate Agent',
      fr: 'Hubert G., Agent Immobilier'
    },
    initials: 'HG'
    // image: '/testimony-3.png'
  },
  {
    message: {
      en: 'I’ve made some good moves by investing in crypto. Today, I want to use part of my holdings to buy a home for my family. But it’s a real challenge. Fortunately, I now know that Digital Deposit is there to help me realize my project.',
      fr: 'J’ai fait quelques bons coups en investissant dans la crypto, aujourd’hui je souhaite réutiliser une partie de mon bag pour acheter un logement pour ma famille. Mais c’est un vrai parcours du combattant. Heureusement, je sais maintenant que Digital Deposit est là pour m’aider dans la réalisation de mon projet.'
    },
    who: {
      en: 'Maxime O., Crypto Investor',
      fr: 'Maxime O., Investisseur Crypto'
    },
    initials: 'MO'
    // image: '/testimony-3.png'
  }
]


export type Testimony = {
  message: I18nValue
  who: I18nValue
  initials?: string
  image?: string
}

export function DDTestimony({ testimony, index }: { testimony: Testimony, index: number }) {

  const screen = useScreen()
  const i18nValue = useI18nValue()

  return (
    <Reveal duration={350} delay={100 + index * 100} translateX={100} className={cn('relative flex w-full flex-col overflow-hidden rounded-lg bg-primary p-4 text-white sm:p-4 lg:w-[calc(25%-16px)]', testimony.image ? 'pt-32' : '')}>
      {testimony.image && <div id='IMAGE' className='absolute left-0 top-0 z-[1] h-full w-full'>
        <Image src={testimony.image} alt={i18nValue(testimony.who)} style={{ objectFit: 'cover' }} fill className='opacity-20' />
      </div>}
      <div id='FOG' className='from-primary/50 to-primary/0 absolute left-0 top-0 z-[2] h-full w-full bg-gradient-to-b' />
      <div id='MESSAGE_CONTAINER' className='relative z-10 p-6 sm:p-8'>
        <BiSolidQuoteAltLeft size={screen.sm ? 28 : 24} className='absolute left-0 top-0' />
        <div id='MESSAGE' className='text-[14px] font-normal sm:text-base'>{i18nValue(testimony.message)}</div>
        <BiSolidQuoteAltRight size={screen.sm ? 28 : 24} className='absolute bottom-0 right-0' />
      </div>
      <div className='min-h-[16px] grow' />
      <Flex id='WHO' className='z-10 flex-nowrap items-center gap-4 px-0 text-sm font-normal opacity-100'>
        {testimony.initials && <Flex className='h-[32px] max-h-[32px] min-h-[32px] w-[32px] min-w-[32px] max-w-[32px] items-center justify-center rounded-full bg-background font-bold text-primary dark:text-primaryBright'>{testimony.initials}</Flex>}
        {i18nValue(testimony.who)}
      </Flex>
    </Reveal>
  )
}

export function DDTestimonies() {

  return (
    <Section containerClassName='bg-background2'>

      <Flex id='TESTIMONIES' className='w-full flex-col justify-between gap-4 lg:flex-row lg:items-stretch'>
        {TESTIMONIES.map((testimony, i) => <DDTestimony testimony={testimony} key={i} index={i} />)}
      </Flex>

    </Section>
  )
}
