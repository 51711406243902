import(/* webpackMode: "eager" */ "/vercel/path0/components/i18n/lang-context-client.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/jbda/assistant.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/jbda/dd/dd-action.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DDFAQ"] */ "/vercel/path0/components/jbda/dd/dd-faq.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DDHowDoesItWork"] */ "/vercel/path0/components/jbda/dd/dd-how-it-works.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DDPromiseBG"] */ "/vercel/path0/components/jbda/dd/dd-promise-bg.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DDReassuringLogos"] */ "/vercel/path0/components/jbda/dd/dd-reassuring-logos.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DDTestimonies"] */ "/vercel/path0/components/jbda/dd/dd-testimonies.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["JBDAPromiseBG"] */ "/vercel/path0/components/jbda/jbda/jbda-promise-bg.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/jbda/newsletter.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/jbda/section.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/jbda/stats.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/jbda/waves.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/reveal.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
